import React, { useState } from 'react';
import SidelineProPaywallModal from '~/components/SidelinePro/Paywall/PaywallModal';
import {
  useIsSidelinePro,
  useIsSidelineProAvailable,
} from '~/hooks/useIsSidelinePro';
import { PaywallSource } from '~/services/analytics/events/sidelinePro';

export default function ManagedPaywall({
  children,
  source,
}: {
  children: React.ReactElement<{ onClick?: React.MouseEventHandler }>;
  source: PaywallSource;
}) {
  const proAvailable = useIsSidelineProAvailable();
  const proEnabled = useIsSidelinePro();
  const [open, setOpen] = useState(false);

  if (!proAvailable) {
    return null;
  }

  if (proEnabled) {
    return children;
  }

  return (
    <>
      {children
        ? React.Children.map(children, child =>
            React.cloneElement(child, {
              onClick: () => setOpen(true),
            }),
          )
        : null}

      <SidelineProPaywallModal
        open={open}
        onClose={() => setOpen(false)}
        source={source}
      />
    </>
  );
}
